<template>
  <Header class="header">
    <img
      src="../assets/images/logo.png"
      alt="logo"
      class="logo"
      @click="goHome"
    />
    <div class="avatar-container">
      <Button v-if="0" type="text" @click.native="goToWalletBinding"
        >绑定钱包</Button
      >
      <div class="username">{{ username }}</div>
      <Dropdown>
        <Avatar v-if="0" icon="ios-person" class="avatar" />
        <span>个人中心</span>
        <DropdownMenu slot="list">
          <DropdownItem @click.native="goToChangePasswordPage">
            修改密码
          </DropdownItem>
          <DropdownItem @click.native="goToWalletBinding">
            绑定钱包
          </DropdownItem>
          <DropdownItem @click.native="logout">登出</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  </Header>
</template>

<script>
import {
  Header,
  Avatar,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Button,
} from "view-design";

export default {
  name: "AppHeader",
  components: {
    Header,
    Avatar,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Button,
  },
  methods: {
    logout() {
      this.$store.commit("logout");
      this.$router.push("/login");
    },
    goHome() {
      this.$router.push("/");
    },
    goToChangePasswordPage() {
      this.$router.push("/change-password");
    },
    goToWalletBinding() {
      this.$router.push("/wallet-binding");
    },
  },
  computed: {
    username() {
      return this.$store.state.userInfo.username;
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #0b0e17;
}

.avatar-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.username {
  margin-right: 10px;
  color: white;
}

.avatar {
  cursor: pointer;
}

.logo {
  height: 80%;
  cursor: pointer;
}
</style>
